import { useCallback } from "react";

import { getAccessToken } from "../useAuth/useAuth";

export default function useCustomer() {
  /**
   * Customer updates info
   */
  const updateCustomerInfo = useCallback(async (params, userId) => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/profiles/v1/customers/${userId}`;
    const accessToken = getAccessToken();

    return fetch(endpointUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(params),
      method: "PATCH",
    }).then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message || "There was an error updating customer info"
        );

        return Promise.reject(error);
      }

      return jsonResponse;
    });
  }, []);

  /**
   * Customer deletes account
   */
  const deleteCustomerAccount = useCallback(async (userId) => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/profiles/v1/customers/${userId}`;
    const accessToken = getAccessToken();

    return fetch(endpointUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      // body: JSON.stringify(params),
      method: "DELETE",
    }).then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message || "There was an error deleting customer account"
        );

        return Promise.reject(error);
      }

      return jsonResponse;
    });
  }, []);

  /**
   * Customer contacts support
   */
  const sendSupportRequest = useCallback(
    async (name, email, subject, request) => {
      const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/notifications/v1/notifications/contact-support`;
      const accessToken = getAccessToken();

      return fetch(endpointUrl, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          name,
          email,
          subject,
          question: request,
        }),
        method: "POST",
      }).then(async (res) => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const error = new Error(
            jsonResponse.message || "There was an error sending support request"
          );

          return Promise.reject(error);
        }

        return jsonResponse;
      });
    },
    []
  );

  /**
   * Send customer verification email
   */
  const sendEmailVerificationRequest = useCallback(async (userId) => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/profiles/v1/customers/${userId}/verification/send`;
    const accessToken = getAccessToken();

    return fetch(endpointUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      method: "POST",
    }).then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message ||
            "There was an error sending email verification request"
        );

        return Promise.reject(error);
      }

      return jsonResponse;
    });
  }, []);

  /**
   * Customer confirms email
   */
  const confirmCustomerEmail = useCallback(async (customerId, email, code) => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/profiles/v1/customers/${customerId}/verification/confirm?email=${email}&code=${code}`;

    return fetch(endpointUrl, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    }).then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message || "There was an error confirming email"
        );

        return Promise.reject(error);
      }

      return jsonResponse;
    });
  }, []);

  return {
    updateCustomerInfo,
    deleteCustomerAccount,
    sendSupportRequest,
    sendEmailVerificationRequest,
    confirmCustomerEmail,
  };
}
