import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useAppState } from "../../state";
import { useSignUpState } from "../../state/SignUpState";
import Cookies from "js-cookie";
import Modal from "react-modal";

// Components
import Header from "../Header/Header";
import Login from "../Login/Login";
import SignUpForm from "../SignUp/SignUpForm";
import SignUpCodeVerification from "../SignUp/CodeVerification/CodeVerification";
import PasswordConfirmForm from "../SignUp/PasswordConfirmForm/PasswordConfirmForm";
import Registration from "../SignUp/Registration/Registration";
import SignUpSetEmail from "../SignUp/SetEmail/SetEmail";
import SuccessDialog from "../SignUp/SuccessDialog/SuccessDialog";
import SetPhoneNumber from "../SignUp/SetPhoneNumber/SetPhoneNumber";
import PasswordReset from "../PasswordReset/PasswordReset";
import PasswordResetCodeVerification from "../PasswordReset/CodeVerification/CodeVerification";
import ScrollToTopOnMount from "../ScrollToTopOnMount/ScrollToTopOnMount";
import Button from "../Button/Button";

// Icons
import CheckNiceIcon from "../../icons/CheckNiceIcon";
import BlockerIcon from "../../icons/BlockerIcon";

// Hooks
import useTheme from "../ThemeProvider/useTheme/useTheme";

// Utils
import { clientWidth } from "../../utils";
import { modalStyles } from "../../constants";

export default function LoginPage() {
  const history = useHistory();
  const location = useLocation();

  const { theme } = useTheme();

  const { referralCode, setReferralCode, applyReferral } = useAppState();

  const {
    authState,
    isAppleSignIn,
    authVendor,
    userFirstName,
    userFamilyName,
    setUserFirstName,
    setUserFamilyName,
    setUserEmail,
    setAuthState,
    setAuthVendor,
    setAccessToken,
    setIdentityToken,
    setIsAppleSignIn,
  } = useSignUpState();

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [infoModalTitle, setInfoModalTitle] = useState("");
  const [infoModalMsg, setInfoModalMsg] = useState("");

  const infoModalResolveRef = useRef(null);

  let CurrentState,
    onNext = () => {};

  useEffect(() => {
    // From Coach Profile page
    const query = new URLSearchParams(window.location.search);

    const authState = query.get("authState");

    if (authState) {
      setAuthState(authState);
    }

    const authVendor = query.get("authVendor");

    if (authVendor) {
      setAuthVendor(authVendor);
    }

    const accessToken = query.get("accessToken");

    if (accessToken) {
      setAccessToken(accessToken);
    }

    const identityToken = query.get("identityToken");

    if (identityToken) {
      setIdentityToken(identityToken);
    }

    const email = query.get("email");

    if (email) {
      setUserEmail(email);
    }

    const firstName = query.get("firstName");

    if (firstName) {
      setUserFirstName(firstName);
    }

    const lastName = query.get("lastName");

    if (lastName) {
      setUserFamilyName(lastName);
    }
  }, [location]);

  const openInfoModal = useCallback((msg, title = "Sorry") => {
    return new Promise((resolve, reject) => {
      infoModalResolveRef.current = resolve;

      setIsInfoModalOpen(true);
      setInfoModalTitle(title);
      setInfoModalMsg(msg);
    });
  }, []);

  const closeInfoModal = useCallback(() => {
    infoModalResolveRef.current();
    infoModalResolveRef.current = null;

    setIsInfoModalOpen(false);
  }, []);

  const redirectToPreviousUrl = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const returnTo = urlParams.get("return_to");

    if (returnTo) {
      urlParams.delete("return_to");
      urlParams.delete("authState");

      const returnUrl = new URL(
        decodeURIComponent(returnTo),
        window.location.origin
      );
      const pathname = returnUrl.pathname;
      const search = returnUrl.search;

      history.replace({
        pathname,
        state: location.state,
        search: search
          ? `${search}${urlParams.size > 0 ? `&${urlParams.toString()}` : ""}`
          : urlParams.size > 0
          ? `?${urlParams.toString()}`
          : "",
      });
    } else {
      // Apple Login??
      history.replace(
        (location && location.state && location.state.from) || {
          pathname: "/partners",
          //search: window.location.search,
        }
      );
    }
  }, [history, location]);

  const onSignIn = (userInfo) => {
    if (userInfo) {
      if (referralCode) {
        applyReferral(userInfo.id, referralCode)
          .then((res) => {
            // Referral code is valid

            return openInfoModal(
              `You received ${res.data.symbol}${res.data.receiverReward} referral credits.`,
              "Referral Bonus Applied"
            );
          })
          .catch((res) => {
            return openInfoModal(res.message, "Link Error");
          })
          .then(() => {
            redirectToPreviousUrl();
          })
          .finally(() => {
            // Regardless the referral code is valid or not we don't need it anymore
            // (so it won't be applied on re-login for example)
            setReferralCode("");

            Cookies.remove("referral");
          });
      } else {
        redirectToPreviousUrl();
      }
    }
  };

  switch (authState) {
    case "login":
      CurrentState = Login;
      onNext = onSignIn;

      break;
    case "verifyPhoneNumber":
      CurrentState = SignUpForm;
      onNext = () => setAuthState("codeVerification");

      break;
    case "codeVerification":
      CurrentState = SignUpCodeVerification;
      onNext = () =>
        isAppleSignIn || authVendor === "google" || authVendor === "facebook"
          ? // ? userFirstName && userFamilyName ?  :
            setAuthState("setFullName")
          : setAuthState("passwordSet");

      break;
    case "passwordSet":
      CurrentState = PasswordConfirmForm;
      onNext = () => setAuthState("setFullName");

      break;
    case "setFullName":
      CurrentState = Registration;
      onNext = () => setAuthState("setEmail");

      break;
    case "setEmail":
      CurrentState = SignUpSetEmail;
      onNext = onSignIn;

      break;
    case "registrationSuccess":
      CurrentState = SuccessDialog;

      break;
    case "passwordResetCodeVerification":
      CurrentState = PasswordResetCodeVerification;

      break;
    case "passwordReset":
      CurrentState = PasswordReset;

      break;
    case "setPhoneNumber":
      CurrentState = SetPhoneNumber;
      setIsAppleSignIn(true);
      // After services we got here
      onNext = () => setAuthState("codeVerification");

      break;
    default:
      CurrentState = SignUpForm;
  }

  return (
    <>
      <ScrollToTopOnMount />
      <Header showLinks />

      <div className="tbk-flex tbk-min-h-screen tbk-flex-col tbk-items-center tbk-pt-5 tbk-pb-8 md:tbk-justify-center md:tbk-pt-6">
        <div className={"tbk-relative tbk-w-full tbk-p-3 sm:tbk-w-120"}>
          {authState === "" || authState === "signUp" ? (
            <SignUpForm
              title={"Sign Up or Login"}
              ctaText={"Continue"}
              dividerText={"Or sign-up with:"}
              returnTo={location.state && location.state.from}
              onNext={(authVendor) =>
                authVendor === "apple" ||
                authVendor === "google" ||
                authVendor === "facebook"
                  ? setAuthState("setPhoneNumber")
                  : setAuthState("codeVerification")
              }
            />
          ) : (
            React.createElement(CurrentState, {
              onNext,
            })
          )}
        </div>
      </div>

      <Modal
        appElement={/*__CLIENT__ && */ document.getElementById("#app")}
        isOpen={isInfoModalOpen}
        // onAfterOpen={afterOpenInfoModal}
        onRequestClose={closeInfoModal}
        style={
          clientWidth() < theme.responsive.breakpoints.sm
            ? modalStyles.infoMobile
            : modalStyles.info
        }
        contentLabel="Info Modal"
      >
        <div className="modal-info tbk-flex tbk-flex-col tbk-items-center">
          <h3 className="tbk-text-title-bold tbk-mt-0 tbk-mb-1 tbk-text-center tbk-text-grey-main">
            {infoModalTitle}
          </h3>
          <p className="tbk-text-main tbk-mb-4 tbk-max-w-full tbk-text-center tbk-text-grey-main">
            {infoModalMsg}
          </p>
          {infoModalTitle === "Referral Bonus Applied" ? (
            <CheckNiceIcon size={40} className="tbk-mb-4 tbk-text-green" />
          ) : (
            <BlockerIcon size={40} className="tbk-mb-4 tbk-text-green" />
          )}
          <Button className="tbk-w-full" onClick={closeInfoModal}>
            Ok
          </Button>
        </div>
      </Modal>
    </>
  );
}
