import React from "react";

export default function LockIcon({ size }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8811 11.1693C17.6808 10.9689 17.4376 10.8688 17.1513 10.8688H16.808V8.80806C16.808 7.49157 16.3359 6.36119 15.3914 5.41674C14.447 4.47225 13.3168 4 12.0001 4C10.6834 4 9.55295 4.47225 8.60861 5.4167C7.66412 6.36119 7.19202 7.49153 7.19202 8.80806V10.8688H6.84863C6.56253 10.8688 6.31921 10.9689 6.11886 11.1693C5.9185 11.3695 5.81836 11.6128 5.81836 11.8991V18.0809C5.81836 18.367 5.91854 18.6104 6.11886 18.8108C6.31921 19.011 6.56253 19.1112 6.84863 19.1112H17.1515C17.4378 19.1112 17.681 19.0111 17.8814 18.8108C18.0816 18.6104 18.1819 18.367 18.1819 18.0809V11.899C18.1821 11.6129 18.0816 11.3696 17.8811 11.1693ZM14.7475 10.8688H9.25264V8.80806C9.25264 8.04967 9.521 7.40211 10.0576 6.86558C10.5943 6.32898 11.2417 6.06074 12.0002 6.06074C12.7587 6.06074 13.406 6.32894 13.9427 6.86558C14.4792 7.40207 14.7475 8.04967 14.7475 8.80806V10.8688Z"
        fill="currentColor"
      />
    </svg>
  );
}
