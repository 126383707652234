// Must use `import *` or named imports for React's types
// import {FunctionComponent} from 'react';
// import * as stripeJs from '@stripe/stripe-js';

import React from "react";
import PropTypes from "prop-types";

import ApplePay from "./ApplePay";

const PaymentRequestButtonElementComponent = ({
  id,
  className,
  disabled,
  onClick,
}) => {
  return (
    <button
      id={id}
      className={`tb-button tbk-cursor-pointer tbk-border-basic-trueblack tbk-bg-basic-trueblack tbk-text-basic-white hover:tbk-border-basic-trueblack/80 hover:tbk-bg-basic-trueblack/80 focus:tbk-border-basic-trueblack focus:tbk-bg-basic-trueblack active:tbk-border-basic-trueblack active:tbk-bg-basic-trueblack active:tbk-text-basic-white disabled:tbk-border-grey-main disabled:tbk-bg-grey-main disabled:tbk-text-blue-grey ${
        className ? ` ${className}` : ""
      }${disabled ? " disabled" : ""}`}
      disabled={disabled}
      onClick={onClick}
    >
      Book with <ApplePay className="tbk-ml-1" />
    </button>
  );
};

PaymentRequestButtonElementComponent.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
};

export default PaymentRequestButtonElementComponent;
