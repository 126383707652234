import React from "react";

export default function TimeIcon({ size }) {
  return (
    <svg
      width={size || 24}
      height={size || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="tbk-inline-block tbk-align-text-bottom"
    >
      <path
        d="M11.993 5A6.997 6.997 0 0 0 5 12c0 3.864 3.129 7 6.993 7A7.004 7.004 0 0 0 19 12c0-3.864-3.136-7-7.007-7zM12 6.4c3.094 0 5.6 2.506 5.6 5.6s-2.506 5.6-5.6 5.6A5.598 5.598 0 0 1 6.4 12c0-3.094 2.506-5.6 5.6-5.6zm.35 2.1H11.3v4.2l3.675 2.205.525-.861-3.15-1.869V8.5z"
        fill="currentColor"
      />
    </svg>
  );
}
