import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export default function usePrevState() {
  const history = useHistory();

  const [prevState, setPrevState] = useState([]);
  const [prevLocation, setPrevLocation] = useState(history.location.pathname);

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action === "PUSH") {
        setPrevState((prev) => {
          const newPrevState = [prevLocation, ...prev];
          return newPrevState.slice(0, 2);
        });
        setPrevLocation(location.pathname);
      } else if (action === "POP") {
        setPrevState((prev) => {
          if (prev[0] === prevLocation) {
            return prev.slice(1);
          } else {
            return [prevLocation, ...prev].slice(0, 2);
          }
        });
        setPrevLocation(location.pathname);
      }
    });

    return unlisten;
  }, [history, prevLocation]);

  return { prevState };
}
