import React from "react";

import AppStateProvider /*, { useAppState }*/ from "./state";
import SignUpStateProvider from "./state/SignUpState";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  // useElements,
} from "@stripe/react-stripe-js";
import ServiceWorkerWrapper from "./components/ServiceWorkerWrapper/ServiceWorkerWrapper";
import LoginPage from "./components/LoginPage/LoginPage";
import OurTrainersPage from "./components/OurTrainersPage/OurTrainersPage";
import TrainerProfilePage from "./components/TrainerProfilePage/TrainerProfilePage";
import UserProfilePage from "./components/UserProfilePage/UserProfilePage";
import Homepage from "./components/Homepage/Homepage";
import Checkout from "./components/Checkout/Checkout";
import BookingContainer from "./components/Booking/Booking";
import BecomePartnerPage from "./components/BecomePartnerPage/BecomePartnerPage";
import BecomeMemberPage from "./components/BecomeMemberPage/BecomeMemberPage";
import AboutPage from "./components/AboutPage/AboutPage";
import ContactUsPage from "./components/ContactPage/ContactPage";
import PartnershipsPage from "./components/PartnershipsPage/PartnershipsPage";
import PillarsPage from "./components/PillarsPage/PillarsPage";
import ConfirmEmailPage from "./components/ConfirmEmailPage/ConfirmEmailPage";
import GymPage from "./components/GymPage/GymPage";
import CookieConsent from "./components/CookieConsent/CookieConsent";

import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import RouteWithState from "./components/Route/Route";

import GoogleOAuthProvider from "./components/GoogleOAuthProvider/GoogleOAuthProvider";

import useHeight from "./hooks/useHeight/useHeight";
import "./App.scss";

import "regenerator-runtime/runtime.js";
import TrustSafetyPage from "./components/TrustSafetyPage/TrustSafetyPage";
import CymbiotikaPage from "./components/CymbiotikaPage/CymbiotikaPage";
import LifestyleLP from "./components/LifestyleLP/LifestyleLP";
import LifestyleUpcomingPage from "./components/LifestyleLP/LifestyleUpcomingPage/LifestyleUpcomingPage";
import LifestyleCompletedPage from "./components/LifestyleLP/LifestyleCompletedPage/LifestyleCompletedPage";

import EventPage from "./components/EventPage/EventPage";
import OfferPage from "./components/OfferPage/OfferPage";
import SpiritualGangsterLandingPage from "./components/SpiritualGangsterLP/SpiritualGansterLP";
import SuccessPage from "./components/SuccessPage/SuccessPage";
import CancelPage from "./components/CancelPage/CancelPage";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const fonts = [
  {
    cssSrc:
      "https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap",
  },
];

const options = {
  currency: "usd",
  // Fully customizable with appearance API.
  fonts,
};

export default function App() {
  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.

  const height = useHeight();

  // SafeAreaProvider
  return (
    <div id="app" style={{ /*position: 'fixed', */ height: `${height}px` }}>
      <Router>
        <ServiceWorkerWrapper />
        <AppStateProvider>
          <Switch>
            <Route exact path="/">
              <Homepage />
            </Route>

            <Route exact path="/gyms/:GymId">
              <GymPage />
            </Route>

            <Route exact path="/about">
              <AboutPage />
            </Route>

            <Route exact path="/cymbiotika">
              <CymbiotikaPage />
            </Route>

            <Route exact path="/lifestyle">
              <LifestyleLP />
            </Route>

            <Route exact path="/events">
              <LifestyleLP />
            </Route>

            <Route path="/events/previous">
              <LifestyleCompletedPage />
            </Route>

            <Route path="/events/upcoming">
              <LifestyleUpcomingPage />
            </Route>

            <PrivateRoute exact path="/events/:EventId">
              <EventPage />
            </PrivateRoute>

            <Route exact path="/offers/:OfferId">
              <OfferPage />
            </Route>

            <Route exact path="/spiritual-gangster">
              <SpiritualGangsterLandingPage />
            </Route>

            <Route exact path="/become-a-member">
              <GoogleOAuthProvider
                clientId={process.env.REACT_APP_GOOGLE_GSI_CLIENT_ID}
              >
                <SignUpStateProvider>
                  <BecomeMemberPage />
                </SignUpStateProvider>
              </GoogleOAuthProvider>
            </Route>
            <Route exact path="/become-a-coach">
              <BecomePartnerPage />
            </Route>
            <Route exact path="/become-a-partner">
              <BecomePartnerPage />
            </Route>
            <PrivateRoute path="/account">
              <Elements stripe={stripePromise} options={options}>
                <UserProfilePage />
              </Elements>
            </PrivateRoute>
            <RouteWithState
              exact
              path={[
                "/partners",
                "/partners/fitness",
                "/partners/spirit",
                "/partners/personal-training",
                "/partners/boxing",
                "/partners/kickboxing",
                "/partners/ballet",
                "/partners/ballet-fit",
                "/partners/running",
                "/partners/prepost-natal",
                "/partners/yoga",
                "/partners/pilates",
                "/partners/stretching",
                "/partners/meditation",
                "/partners/sound-baths",
                "/partners/sound-healing",
                "/partners/lifestyle",
                "/partners/lifestyle-coaching",
                "/partners/stress-management",
                "/partners/motivation",
                "/partners/motivational-coaching",
                "/partners/cymbiotika-certified",
                "/partners/spiritual-gangster",
                "/partners/spiritual-gangster-certified",
              ]}
            >
              <GoogleOAuthProvider
                clientId={process.env.REACT_APP_GOOGLE_GSI_CLIENT_ID}
              >
                <SignUpStateProvider>
                  <OurTrainersPage />
                </SignUpStateProvider>
              </GoogleOAuthProvider>
            </RouteWithState>
            <RouteWithState exact path="/partners/:Id">
              <GoogleOAuthProvider
                clientId={process.env.REACT_APP_GOOGLE_GSI_CLIENT_ID}
              >
                <SignUpStateProvider>
                  <TrainerProfilePage />
                </SignUpStateProvider>
              </GoogleOAuthProvider>
            </RouteWithState>
            <PrivateRoute exact path="/checkout">
              <Elements stripe={stripePromise} options={options}>
                <BookingContainer>
                  <Checkout />
                </BookingContainer>
              </Elements>
            </PrivateRoute>
            <Route exact path="/contact">
              <ContactUsPage />
            </Route>
            <Route exact path="/partnerships">
              <PartnershipsPage />
            </Route>
            <Route exact path="/pillars">
              <PillarsPage />
            </Route>
            <Route exact path="/trust-and-safety">
              <TrustSafetyPage />
            </Route>

            <Route exact path="/memberships/success">
              <SuccessPage />
            </Route>

            <Route exact path="/bookings/success">
              <SuccessPage />
            </Route>

            <Route exact path="/payments/cancel">
              <CancelPage />
            </Route>

            {/*<Route exact path="/password-reset">
              <PasswordResetPage />
            </Route>
            <Route exact path="/password-reset-instructions">
              <PasswordResetInstructionsSent />
            </Route>
            <Route exact path="/password-reset-new-password">
              <PasswordResetNewPassword />
            </Route>*/}
            <Route path="/login">
              <GoogleOAuthProvider
                clientId={process.env.REACT_APP_GOOGLE_GSI_CLIENT_ID}
              >
                <SignUpStateProvider>
                  <LoginPage />
                </SignUpStateProvider>
              </GoogleOAuthProvider>
            </Route>
            <Route path="/email-confirm">
              <ConfirmEmailPage />
            </Route>
            <Redirect to="/" />
          </Switch>
        </AppStateProvider>
      </Router>
      <CookieConsent />
    </div>
  );

  // </SafeAreaProvider>
}
