import React, { useEffect, useState } from "react";

import { useStripe } from "../StripeProvider/StripeProvider";
// import {PaymentRequestButtonElement, useStripe, useElements} from '@stripe/react-stripe-js';

// import StatusMessages, {useMessages} from './StatusMessages';

export const paymentRequestApi =
  process.env.REACT_APP_PAYMENT_REQUEST_API === "yes";
export const merchantIdentifier = process.env.REACT_APP_MERCHANT_ID;

export const isPaymentRequestAvailable = () => {
  return new Promise((resolve, reject) => {
    try {
      const enabled = window.PaymentRequest ? true : false;
      resolve(enabled);
    } catch (err) {
      reject(err);
    }
  });
};

const existsApplePayJsApi = () => {
  return new Promise((resolve, reject) => {
    try {
      const enabled =
        window.ApplePaySession && window.ApplePaySession.canMakePayments();
      resolve(enabled);
    } catch (err) {
      reject(err);
    }
  });
};

export const isApplePayJsAvailable = () => {
  return existsApplePayJsApi().then(() => {
    return window.ApplePaySession.canMakePaymentsWithActiveCard(
      merchantIdentifier
    );
  });
};

export const isApplePayAvailable = () => {
  return paymentRequestApi || true
    ? isPaymentRequestAvailable()
    : isApplePayJsAvailable();
};

const ApplePay = () => {
  const { stripe } = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [isCreatePaymentIntentFetching, setIsCreatePaymentIntentFetching] =
    useState(false);

  useEffect(() => {
    if (!stripe /* || !elements*/) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: "Demo total",
        amount: 1999,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    // Check the availability of the Payment Request API.
    pr.canMakePayment().then((result) => {
      if (result) {
        setPaymentRequest(pr);
      }
    });

    pr.on("paymentmethod", async (e) => {
      // if (!isClientSecretFetching && !isCreatePaymentIntentFetching) {
      //   createSetupIntent(user.id).then((res) => {
      //     isCreatePaymentIntentFetching(true);
      //
      //     stripe
      //       .handleCardSetup(res.data.clientSecret, cardElement, {
      //         payment_method_data: {
      //           billing_details: { name: `${user.firstName} ${user.lastName}` },
      //         },
      //       })
      //       .then((result) => {
      //         if (result.error) {
      //           // Display error.message in your UI.
      //           openInfoModal(result.error.message);
      //         } else {
      //           // The setup has succeeded. Display a success message.
      //           params = {
      //             customerId: user.id,
      //             paymentMethod: result.setupIntent.payment_method,
      //             provider: "CARD",
      //           };
      //
      //           registerPaymentMethod(params).then((res) => {
      //             // Some dirty hack
      //             setDefaultPaymentMethodRef.current(res.data.id, {
      //               customerId: res.data.customerId,
      //             });
      //
      //             addCardModalResolveRef.current
      //               ? addCardModalResolveRef.current(res.data) // TODO: promise might be resolved two times on close
      //               : closeAddCardModal();
      //           });
      //         }
      //       })
      //       .finally(() => {
      //         setIsCreatePaymentIntentFetching(false);
      //       });
      //   });
      // }
      // const { error: backendError, clientSecret } = await fetch(
      //   '/create-payment-intent',
      //   {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //     body: JSON.stringify({
      //       paymentMethodType: 'card',
      //       currency: 'usd',
      //     }),
      //   }
      // ).then((r) => r.json());
      //
      // if (backendError) {
      //   addMessage(backendError.message);
      //   return;
      // }
      //
      // addMessage('Client secret returned');
      //
      // const {
      //   error: stripeError,
      //   paymentIntent,
      // } = await stripe.confirmCardPayment(clientSecret, {
      //   payment_method: e.paymentMethod.id,
      // }, { handleActions: false });
      //
      // if (stripeError) {
      //   // Show error to your customer (e.g., insufficient funds)
      //   addMessage(stripeError.message);
      //   return;
      // }
      // Show a success message to your customer
      // There's a risk of the customer closing the window before callback
      // execution. Set up a webhook or plugin to listen for the
      // payment_intent.succeeded event that handles any business critical
      // post-payment actions.
      // addMessage(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
    });
  }, [stripe]);

  return (
    <>
      <h1>Apple Pay</h1>

      <p>
        Before you start, you need to:
        <ul>
          <li>
            <a
              href="https://stripe.com/docs/stripe-js/elements/payment-request-button#html-js-testing"
              target="_blank"
            >
              Add a payment method to your browser.
            </a>{" "}
            For example, add a card to your Wallet for Safari.
          </li>
          <li>
            Serve your application over HTTPS. This is a requirement both in
            development and in production. One way to get up and running is to
            use a service like{" "}
            <a
              href="https://ngrok.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              ngrok
            </a>
            .
          </li>
          <li>
            <a
              href="https://stripe.com/docs/stripe-js/elements/payment-request-button#verifying-your-domain-with-apple-pay"
              target="_blank"
            >
              Verify your domain with Apple Pay
            </a>
            , both in development and production.
          </li>
        </ul>
      </p>

      <a
        href="https://stripe.com/docs/stripe-js/elements/payment-request-button"
        target="_blank"
      >
        Stripe Documentation
      </a>

      {paymentRequest && <div options={{ paymentRequest }} />}

      {/*<StatusMessages messages={messages} />*/}

      <p>
        {" "}
        <a href="https://youtu.be/bMCsJfJyQKA" target="_blank">
          Watch a demo walkthrough
        </a>{" "}
      </p>
    </>
  );
};

export default ApplePay;
