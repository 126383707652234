import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAppState } from "../../state";

export default function PrivateRoute({
  children,
  redirectTo = "/become-a-member",
  ...rest
}) {
  const { hasTriedToAutoLogin, user, prevState } = useAppState();

  const renderChildren = !!user;

  if (!renderChildren && !hasTriedToAutoLogin) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        renderChildren ? (
          React.cloneElement(children, { prevState })
        ) : (
          <Redirect
            to={{
              pathname: redirectTo,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
