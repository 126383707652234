import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useAppState } from "../../state";
import { useSignUpState } from "../../state/SignUpState";

// Components
import Button from "../Button/Button";
import PasswordInput from "../PasswordInput/PasswordInput";

// Icons
import ArrowRightIcon from "../../icons/ArrowRightIcon";

export default function LoginForm({ onNext, onCloseClick }) {
  // const history = useHistory();
  // const location = useLocation();

  const { signIn, isFetching } = useAppState();
  const { setAuthState, userPhoneCode, userPhoneNumber } = useSignUpState();

  const [canSend, setCanSend] = useState(false);
  const [password, setPassword] = useState("");
  const [hasError, setHasError] = useState(false);

  const passwordRef = useRef();

  useEffect(() => {
    if (passwordRef.current) {
      passwordRef.current.focus();
    }
  }, []);

  const handlePasswordChange = useCallback((e) => {
    const value = e.target.value;

    setPassword(value);
    setHasError(false);

    if (value) {
      setCanSend(true);
    } else {
      setCanSend(false);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (userPhoneCode && userPhoneNumber && password) {
      setHasError(false);

      signIn(userPhoneCode, userPhoneNumber, password)
        .then((res) => {
          onNext(res);
        })
        .catch((err) => {
          if (err) {
            setHasError(true);
          }
        });
    }
  };

  return (
    <>
      <div className="tbk-text-title-bold tbk-pb-1 tbk-text-grey-main">
        Your Password
      </div>
      <p className="tbk-text-main tbk-pb-4 tbk-text-grey-main">
        Enter your password for {userPhoneCode} {userPhoneNumber}.
      </p>
      <form onSubmit={handleSubmit}>
        <div className="tbk-mb-1 tbk-flex">
          <PasswordInput
            ref={passwordRef}
            hasError={hasError}
            style={{ marginBottom: 0 }}
            placeholder="Your password"
            value={password}
            onChange={handlePasswordChange}
            // onKeyUp={handlePasswordChange}
          />
        </div>
        {hasError ? (
          <div className="tbk-text-main tbk--mt-2 tbk-mb-2 tbk-text-center tbk-text-coral">
            Unable to log in. Please check your password.
          </div>
        ) : null}
        <div className="tbk-text-small tbk-mb-3 tbk-text-grey-main">
          Forgot your password?{" "}
          <span
            role="button"
            className="tb-link tbk-cursor-pointer"
            onClick={() => setAuthState("passwordReset")}
          >
            Reset password
          </span>
        </div>
        <Button
          type="submit"
          className="tb-button tbk-mb-4 tbk-w-full"
          disabled={!canSend || isFetching}
          showLoader={isFetching}
          endIcon={canSend ? <ArrowRightIcon /> : null}
        >
          Log in
        </Button>
      </form>
    </>
  );
}
