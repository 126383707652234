/* global Sentry */
import { useCallback, useState } from "react";
import * as Sentry from "@sentry/browser";

import { getAccessToken } from "../useAuth/useAuth";

import { makeCancelable, toQueryString } from "../../utils";

/* Customer gets the Secret */

const fetchClientSecret = (customerId, region = "US") => {
  const endpointUrl = `${
    process.env.REACT_APP_API_BASE_URL
  }/payments/v1/methods/intents?${toQueryString({
    customerId,
  })}`;
  const accessToken = getAccessToken();

  const request = new Request(endpointUrl, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    method: "POST",
  });

  const cancelablePromise = makeCancelable(fetch(request));

  const requestPromise = cancelablePromise.promise.then(async (res) => {
    const jsonResponse = await res.json();

    if (!res.ok) {
      const error = new Error(
        jsonResponse.error || `There was an error creating payment intent`
      );

      return Promise.reject(error);
    }

    return jsonResponse;
  });

  return {
    ...cancelablePromise,
    promise: requestPromise,
  };
};

const fetchItems = (partnerId, type, region = "US") => {
  const endpointUrl = `${
    process.env.REACT_APP_API_BASE_URL
  }/payments/v1/items?${toQueryString(
    Object.assign(
      {
        type,
        region,
      },
      partnerId && { partnerId }
    )
  )}`;
  const accessToken = getAccessToken();

  const headers = {
    "content-type": "application/json",
    // Authorization: `Bearer ${accessToken}`,
  };

  // if (accessToken) {
  //   headers.Authorization = `Bearer ${accessToken}`;
  // }

  const request = new Request(endpointUrl, {
    headers,
  });

  const cancelablePromise = makeCancelable(fetch(request));

  const requestPromise = cancelablePromise.promise.then(async (res) => {
    const jsonResponse = await res.json();

    if (!res.ok) {
      const error = new Error(
        jsonResponse.error || `There was an error getting items`
      );

      return Promise.reject(error);
    }

    return jsonResponse;
  });

  return {
    ...cancelablePromise,
    promise: requestPromise,
  };
};

export default function usePayment() {
  const [prices, setPrices] = useState([]);
  const [discount, setDiscount] = useState(null);
  const [referral, setReferral] = useState(null);
  const [referralValidation, setReferralValidation] = useState(null);
  const [packages, setPackages] = useState([]);
  const [activePackage, setActivePackage] = useState(null);
  const [activePackageLoaded, setActivePackageLoaded] = useState([]);

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethodsLoaded, setPaymentMethodsLoaded] = useState(false);
  const [isClientSecretFetching, setIsClientSecretFetching] = useState(false);
  const [isPromoCodeFetching, setIsPromoCodeFetching] = useState(false);
  const [bookingItems, setBookingItems] = useState([]);
  const [bookingPackageItems, setBookingPackageItems] = useState([]);
  const [membershipItems, setMembershipItems] = useState([]);
  const [membershipAdditionalEventItems, setMembershipAdditionalEventItems] =
    useState([]);

  const [cartItems, setCartItems] = useState([]);
  const [checkoutSession, setCheckoutSession] = useState();
  const [previewCheckoutSession, setPreviewCheckoutSession] = useState();
  // const [checkoutSessionId, setCheckoutSessionId] = useState();

  const createSetupIntent = useCallback((customerId) => {
    setIsClientSecretFetching(true);

    return fetchClientSecret(customerId).promise.finally(() => {
      setIsClientSecretFetching(false);
    });
  }, []);

  /**
   * Customer creates payment method
   */
  const registerPaymentMethod = useCallback(
    async (params) => {
      const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/payments/v1/methods`;
      const accessToken = getAccessToken();

      return fetch(endpointUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(params),
        method: "POST",
      }).then(async (res) => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const error = new Error(
            jsonResponse.message ||
              "There was an error creating customer payment method"
          );

          return Promise.reject(error);
        }

        let paymentMethodsCopy = paymentMethods.slice();

        // Remove old ApplePay method
        if (jsonResponse.data.provider === "APPLE") {
          paymentMethodsCopy = paymentMethodsCopy.filter(
            (val) => val.provider !== "APPLE"
          );
        }

        // addNewPaymentMethods();
        let newPaymentMethods = paymentMethodsCopy.concat([jsonResponse.data]);

        setPaymentMethods(newPaymentMethods);

        return jsonResponse;
      });
    },
    [paymentMethods]
  );

  /**
   * Customer gets payment methods
   */
  const loadPaymentMethods = useCallback(async (userId, queryParams) => {
    const endpointUrl = `${
      process.env.REACT_APP_API_BASE_URL
    }/payments/v1/methods?customerId=${userId}${
      queryParams ? `&${toQueryString(queryParams)}` : ""
    }`;
    const accessToken = getAccessToken();

    return fetch(endpointUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      // body: JSON.stringify(params),
      // method: "PATCH",
    }).then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message ||
            "There was an error loading customer payment methods"
        );

        return Promise.reject(error);
      }

      setPaymentMethods(jsonResponse.data);
      setPaymentMethodsLoaded(true);

      return jsonResponse;
    });
  }, []);

  /**
   * Customer sets default payment method
   */
  const setDefaultPaymentMethod = useCallback(
    async (id, params) => {
      const endpointUrl = `${
        process.env.REACT_APP_API_BASE_URL
      }/payments/v1/methods/${id}/main?${toQueryString(params)}`;
      const accessToken = getAccessToken();

      return fetch(endpointUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        // body: JSON.stringify(params),
        method: "POST",
      }).then(async (res) => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const error = new Error(
            jsonResponse.message ||
              "There was an error setting customer default payment method"
          );

          return Promise.reject(error);
        }

        let newPaymentMethods = paymentMethods
          .slice()
          .map((pm) =>
            pm.id === jsonResponse.data.id
              ? jsonResponse.data
              : { ...pm, main: false }
          );

        setPaymentMethods(newPaymentMethods);

        return jsonResponse;
      });
    },
    [paymentMethods]
  );

  /**
   * Customer gets prices (Single Session, bundles)
   */
  const loadPrices = useCallback(async (partnerId, type = "OFFLINE") => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/payments/v1/prices?partnerId=${partnerId}&type=${type}`;
    // const accessToken = getAccessToken();

    return fetch(endpointUrl, {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${accessToken}`,
      },
    }).then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message || "There was an error loading customer prices"
        );

        return Promise.reject(error);
      }

      setPrices(jsonResponse.data.content);

      return jsonResponse.data.content;
    });
  }, []);

  /**
   * Customer pays for booking
   */
  const createPayment = useCallback(async (params) => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/payments/v1/payments`;
    const accessToken = getAccessToken();

    return fetch(endpointUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(params),
      method: "POST",
    }).then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message ||
            "There was an error creating customer payment for booking"
        );

        return Promise.reject(error);
      }

      return jsonResponse;
    });
  }, []);

  /**
   * Customer creates Apple method
   */
  const createAppleMethod = useCallback(
    async (params) => {
      const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/payments/v1/methods/apple`;
      const accessToken = getAccessToken();

      return fetch(endpointUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(params),
        method: "POST",
      }).then(async (res) => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const error = new Error(
            jsonResponse.message ||
              "There was an error creating customer apple method"
          );

          return Promise.reject(error);
        }

        let newPaymentMethods = paymentMethods
          .slice()
          .concat([jsonResponse.data]);

        setPaymentMethods(newPaymentMethods);

        return jsonResponse;
      });
    },
    [paymentMethods]
  );

  /**
   * Customer gets Booking items
   */
  const getBookingItems = useCallback(
    async (partnerId, region = "US") => {
      return fetchItems(partnerId, "BOOKING", region).promise.then((res) => {
        setBookingItems(res.data);
      });
    },
    [paymentMethods]
  );

  /**
   * Customer gets Booking Package items
   */
  const getBookingPackageItems = useCallback(
    async (partnerId, region = "US") => {
      return fetchItems(partnerId, "BOOKING_PACKAGE", region).promise.then(
        (res) => {
          setBookingPackageItems(res.data);
        }
      );
    },
    []
  );

  /**
   * Customer gets Membership items
   */
  const getMembershipItems = useCallback(async (partnerId, region = "US") => {
    return fetchItems(partnerId, "EVENT_MEMBERSHIP,EVENT", region).promise.then(
      (res) => {
        setMembershipItems(res.data);
      }
    );
  }, []);

  /**
   * Customer gets Ьуьиукыршз items
   */
  const getMembershipAdditionalEventItems = useCallback(
    async (partnerId, region = "US") => {
      return fetchItems(
        partnerId,
        "EVENT_MEMBERSHIP_ADDITIONAL_EVENT",
        region
      ).promise.then((res) => {
        setMembershipAdditionalEventItems(res.data);
      });
    },
    []
  );

  /**
   * Customer creates Apple method
   */
  const getCart = useCallback(async (params) => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/payments/v1/carts`;
    const accessToken = getAccessToken();

    return fetch(endpointUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message || "There was an error getting cart products"
        );

        return Promise.reject(error);
      }

      setCartItems(jsonResponse.data);

      return jsonResponse;
    });
  }, []);

  /**
   * Customer adds product to cart
   */
  const addProductToCart = useCallback((params) => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/payments/v1/carts/add-item`;
    const accessToken = getAccessToken();

    const request = new Request(endpointUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(params),
      method: "POST",
    });

    const cancelablePromise = makeCancelable(fetch(request));

    const requestPromise = cancelablePromise.promise.then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message || `There was an error adding product to cart`
        );

        return Promise.reject(error);
      }

      if (jsonResponse.data) {
        setCartItems(jsonResponse.data);
      }

      return jsonResponse;
    });

    return {
      ...cancelablePromise,
      promise: requestPromise,
    };
  }, []);

  /**
   * Customer adds product to cart
   */
  const emptyCart = useCallback((customerId) => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/payments/v1/carts?customerId=${customerId}`;
    const accessToken = getAccessToken();

    const request = new Request(endpointUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      // body: JSON.stringify(params),
      method: "DELETE",
    });

    const cancelablePromise = makeCancelable(fetch(request));

    const requestPromise = cancelablePromise.promise.then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message || "There was an error emptying cart"
        );

        return Promise.reject(error);
      }

      setCartItems(jsonResponse.data);

      return jsonResponse;
    });

    return {
      ...cancelablePromise,
      promise: requestPromise,
    };
  }, []);

  /**
   * Customer creates a checkout session
   * customerId, promocode, applyReferral
   */
  const createCheckoutSession = useCallback(
    async (params, isPreview = false) => {
      const endpointUrl = `${
        process.env.REACT_APP_API_BASE_URL
      }/payments/v1/payments/create-${
        isPreview ? "preview-" : ""
      }session?${toQueryString(params)}`;
      const accessToken = getAccessToken();

      return fetch(endpointUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        // body: JSON.stringify(params),
        method: "POST",
      }).then(async (res) => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const error = new Error(
            jsonResponse.message ||
              "There was an error creating checkout session"
          );

          if (isPreview) {
            setPreviewCheckoutSession(null);

            if (params.promocode) {
              setDiscount({
                promoCode: params.promocode,
                currency: "USD",
                value: 0,
                valueWithCode: 0,
                discount: 0,
                symbol: "$",
              });
            }
          } else {
            setCheckoutSession(null);
          }

          Sentry.captureException(error, {
            extra: {
              response: {
                status: res.status,
                statusText: res.statusText,
                headers: res.headers,
                body: jsonResponse,
              },
            },
          });

          return Promise.reject(error);
        }

        if (isPreview) {
          setPreviewCheckoutSession(jsonResponse.data);

          if (params.promocode) {
            let discount = (
              jsonResponse.data.totalDetails.amountDiscount / 100
            ).toFixed(2);

            // Remove decimal and trailing zeroes if no fractional value
            discount = discount.replace(/\.00$/, "");

            setDiscount({
              promoCode: params.promocode,
              currency: jsonResponse.data.currency,
              value: jsonResponse.data.amountTotal, // Total amount to pay. If promo code was 100%, here would be 0
              valueWithCode: 0,
              discount: Number(discount),
              symbol: jsonResponse.data.symbol,
            });
          }
        } else {
          setCheckoutSession(jsonResponse.data);
        }

        return jsonResponse;
      });
    },
    []
  );

  /**
   * Customer adds product to cart
   */
  const applyReferralr = useCallback(
    async (params) => {
      const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/payments/v1/carts`;
      const accessToken = getAccessToken();

      return fetch(endpointUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(params),
        method: "POST",
      }).then(async (res) => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const error = new Error(
            jsonResponse.message || "There was an error adding product to cart"
          );

          return Promise.reject(error);
        }

        setCartItems(jsonResponse.data);

        return jsonResponse;
      });
    },
    [paymentMethods]
  );

  /**
   * Customer gets discount (from promo code)
   */
  const getDiscount = useCallback(async (params) => {
    const endpointUrl = `${
      process.env.REACT_APP_API_BASE_URL
    }/payments/v1/prices/discount?${toQueryString(params)}`;
    const accessToken = getAccessToken();

    return fetch(endpointUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message || "There was an error loading discount"
        );

        setDiscount(null);

        return Promise.reject(error);
      }

      setDiscount(jsonResponse.data);

      return jsonResponse;
    });
  }, []);

  const clearPrices = useCallback(() => {
    setPrices([]);
  }, []);

  const clearDiscount = useCallback(() => {
    // setDiscount({
    //   currency: "USD",
    //   value: 0,
    //   valueWithCode: 0,
    //   discount: 0,
    //   symbol: "$",
    // });

    setDiscount(null);
  }, []);

  /**
   * Check balance (currency), links for display and navigation
   * customerId string
   * @type {function(*=): Promise<Response>}
   */
  const getReferral = useCallback((customerId) => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/payments/v1/referrals?customerId=${customerId}`;

    const accessToken = getAccessToken();

    return fetch(endpointUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          jsonResponse.message || "There was an error loading referral"
        );

        setReferral(null);

        return Promise.reject(error);
      }

      setReferral(jsonResponse.data);

      return jsonResponse;
    });
  }, []);

  /**
   * customerId string
   * code string
   * @type {function(*=): Promise<Response>}
   */
  const applyReferral = useCallback((customerId, code) => {
    const endpointUrl = `${process.env.REACT_APP_API_BASE_URL}/payments/v1/referrals?customerId=${customerId}&code=${code}`;

    const accessToken = getAccessToken();

    return fetch(endpointUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      method: "POST",
    }).then(async (res) => {
      const jsonResponse = await res.json();

      if (!res.ok) {
        const error = new Error(
          res.status === 500
            ? "You can’t use your own code."
            : jsonResponse.message || "There was an error applying referral"
        );

        setReferralValidation(null);

        return Promise.reject(error);
      }

      setReferralValidation(jsonResponse.data);

      return jsonResponse;
    });
  }, []);

  // Packages

  /**
   * Customer gets packages
   */
  const loadCustomerPackages = useCallback(
    async (customerId, pageableParams) => {
      const endpointUrl = `${
        process.env.REACT_APP_API_BASE_URL
      }/payments/v1/payments/booking-packages?${toQueryString({
        customerId,
        ...pageableParams,
      })}`;
      const accessToken = getAccessToken();

      return fetch(endpointUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }).then(async (res) => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const error = new Error(
            jsonResponse.message ||
              "There was an error loading customer packages"
          );

          return Promise.reject(error);
        }

        setPackages(jsonResponse.data.content);

        return jsonResponse.data.content;
      });
    },
    []
  );

  /**
   * Customer get active package
   */
  const loadCustomerActivePackage = useCallback(
    async (customerId, partnerId) => {
      const endpointUrl = `${
        process.env.REACT_APP_API_BASE_URL
      }/payments/v1/payments/booking-package?${toQueryString({
        customerId,
        partnerId,
      })}`;
      const accessToken = getAccessToken();

      return fetch(endpointUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }).then(async (res) => {
        const jsonResponse = await res.json();

        if (!res.ok) {
          const error = new Error(
            jsonResponse.message ||
              "There was an error loading customer active package"
          );

          return Promise.reject(error);
        }

        setActivePackage(jsonResponse.data);
        setActivePackageLoaded(true);

        return jsonResponse.data && jsonResponse.data.content
          ? jsonResponse.data.content
          : [];
      });
    },
    []
  );

  /**
   * Prevent partner summary caching in Booking Flow
   */
  const clearCustomerActivePackage = useCallback(() => {
    setActivePackage(null);
  }, []);

  /**
   * Prevent partner summary caching in Booking Flow
   */
  const clearCustomerActivePackageLoaded = useCallback(() => {
    setActivePackageLoaded(false);
  }, []);

  return {
    prices,
    discount,
    paymentMethods,
    setPaymentMethods,
    createSetupIntent,
    registerPaymentMethod,
    loadPaymentMethods,
    setDefaultPaymentMethod,
    loadPrices,
    getDiscount,
    clearDiscount,
    createPayment,
    createAppleMethod,
    paymentMethodsLoaded,
    clearPrices,
    getBookingItems,
    getBookingPackageItems,
    getMembershipItems,
    getMembershipAdditionalEventItems,
    getCart,
    addProductToCart,
    emptyCart,
    createCheckoutSession,
    referral,
    referralValidation,
    getReferral,
    applyReferral,
    packages,
    bookingItems,
    bookingPackageItems,
    membershipItems,
    membershipAdditionalEventItems,
    cartItems,
    previewCheckoutSession,
    checkoutSession,
    activePackage,
    activePackageLoaded,
    loadCustomerPackages,
    loadCustomerActivePackage,
    clearCustomerActivePackage,
    clearCustomerActivePackageLoaded,
    isClientSecretFetching,
    isPromoCodeFetching,
  };
}
