import React from "react";
import { Route } from "react-router-dom";
import { useAppState } from "../../state";

export default function RouteWithState({ children, ...rest }) {
  const { prevState } = useAppState();

  return (
    <Route
      {...rest}
      render={({ location }) => React.cloneElement(children, { prevState })}
    />
  );
}
