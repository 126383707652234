import React from "react";

const HeadlineWithSeparator = ({
  className,
  component: Component = "h3", // default component is h3
  titleClass,
  separatorClass = "tbk--mt-1.5 lg:tbk--mt-2",
  children,
}) => {
  return (
    <div className={`tbk-relative tbk-text-center ${className}`}>
      <Component className={`tbk-inline-block tbk-px-2 ${titleClass}`}>
        {children}
      </Component>
      <hr className={`tbk-mb-0 tbk-border-blue-grey-light ${separatorClass}`} />
    </div>
  );
};

export default HeadlineWithSeparator;
