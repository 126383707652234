import React from "react";
import PropTypes from "prop-types";

import LoaderSpinnerIcon from "../../icons/LoaderSpinnerIcon";

const primaryButton = {
  sizes: {
    md: "tbk-px-2 tbk-py-[6px] tbk-rounded-lg tbk-text-base",
    lg: "tbk-px-2 tbk-py-[17px] tbk-rounded-lg tbk-text-lg",
  },
  colors: {
    coral:
      "tbk-bg-coral tbk-border-coral hover:tbk-bg-coral-light hover:tbk-border-coral-light active:tbk-bg-coral active:tbk-border-coral active:tbk-text-basic-white focus:tbk-bg-coral focus:tbk-border-coral disabled:tbk-bg-grey-light disabled:tbk-border-grey-light disabled:tbk-text-blue-grey tbk-text-basic-white",
    blue: "tbk-bg-blue tbk-border-blue hover:tbk-bg-blue-light hover:tbk-border-blue-light active:tbk-bg-blue active:tbk-border-blue active:tbk-text-basic-white focus:tbk-bg-blue focus:tbk-border-blue disabled:tbk-bg-grey-light disabled:tbk-border-grey-light disabled:tbk-text-blue-grey tbk-text-basic-white",
    green:
      "tbk-bg-green tbk-border-green hover:tbk-bg-green-light hover:tbk-border-green-light active:tbk-bg-green active:tbk-border-green active:tbk-text-basic-white focus:tbk-bg-green focus:tbk-border-green disabled:tbk-bg-grey-light disabled:tbk-border-grey-light disabled:tbk-text-green-grey tbk-text-basic-white",
  },
};

const secondaryButton = {
  sizes: {
    md: "tbk-px-2 tbk-py-[5px] tbk-rounded-md tbk-text-base",
    lg: "tbk-px-2 tbk-py-[17px] tbk-rounded-lg tbk-text-lg",
  },
  colors: {
    basic:
      "tbk-bg-basic-white tbk-border-blue-grey-light hover:tbk-bg-blue-grey-light hover:tbk-border-blue-grey-light hover:tbk-text-blue-dark active:tbk-bg-basic-white active:tbk-border-blue-grey-light active:tbk-text-blue-dark focus:tbk-bg-basic-white focus:tbk-border-blue-grey-light focus:tbk-text-blue-dark disabled:tbk-bg-grey-light disabled:tbk-border-grey-light disabled:tbk-text-blue-grey tbk-text-blue-dark",
    coral:
      "tbk-bg-basic-white tbk-border-coral hover:tbk-bg-coral hover:tbk-border-coral active:tbk-text-blue-dark active:tbk-bg-basic-white active:tbk-border-coral focus:tbk-bg-basic-white focus:tbk-border-coral disabled:tbk-bg-grey-light disabled:tbk-border-grey-light disabled:tbk-text-blue-grey tbk-text-blue-dark",
    blue: "tbk-bg-basic-white tbk-border-blue hover:tbk-bg-blue hover:tbk-border-blue active:tbk-text-blue-dark active:tbk-bg-basic-white active:tbk-border-blue focus:tbk-bg-basic-white focus:tbk-border-blue disabled:tbk-bg-grey-light disabled:tbk-border-grey-light disabled:tbk-text-blue-grey tbk-text-blue-dark",
    green:
      "tbk-bg-basic-white tbk-border-green hover:tbk-bg-green hover:tbk-border-green active:tbk-text-green-dark active:tbk-bg-basic-white active:tbk-border-green active:tbk-text-basic-white focus:tbk-bg-basic-white focus:tbk-border-green disabled:tbk-bg-grey-light disabled:tbk-border-grey-light disabled:tbk-text-green-grey tbk-text-green-dark",
    md: {
      basic:
        "tbk-bg-basic-white tbk-border-blue-grey-light hover:tbk-bg-blue-grey-light hover:tbk-border-blue-grey-light hover:tbk-text-blue-dark active:tbk-bg-basic-white active:tbk-border-blue-grey-light active:tbk-text-blue-dark focus:tbk-bg-basic-white focus:tbk-border-blue-grey-light focus:tbk-text-blue-dark disabled:tbk-bg-grey-light disabled:tbk-border-grey-light disabled:tbk-text-blue-grey tbk-text-blue-dark",
      coral:
        "tbk-bg-basic-white tbk-border-coral hover:tbk-bg-coral hover:tbk-border-coral active:tbk-bg-basic-white active:tbk-border-coral focus:tbk-bg-basic-white focus:tbk-border-coral disabled:tbk-bg-grey-light disabled:tbk-border-grey-light disabled:tbk-text-blue-grey tbk-text-coral",
      blue: "tbk-bg-basic-white tbk-border-blue hover:tbk-bg-blue hover:tbk-border-blue active:tbk-bg-basic-white active:tbk-border-blue focus:tbk-bg-basic-white focus:tbk-border-blue disabled:tbk-bg-grey-light disabled:tbk-border-grey-light disabled:tbk-text-blue-grey tbk-text-blue",
      green:
        "tbk-bg-basic-white tbk-border-green hover:tbk-bg-green hover:tbk-border-green active:tbk-bg-basic-white active:tbk-border-green active:tbk-text-basic-white focus:tbk-bg-basic-white focus:tbk-border-green disabled:tbk-bg-grey-light disabled:tbk-border-grey-light disabled:tbk-text-green-grey tbk-text-green",
    },
  },
};

export const Button = React.forwardRef(
  (
    {
      disabled,
      className,
      type,
      showLoader,
      onClick,
      color,
      size,
      variant,
      startIcon,
      endIcon,
      children,
    },
    outerRef
  ) => {
    let colorClasses =
      variant === "secondary"
        ? size === "md"
          ? secondaryButton.colors.md[color || "basic"]
          : secondaryButton.colors[color || "basic"]
        : primaryButton.colors[color || "coral"];

    let sizeClasses =
      variant === "secondary"
        ? secondaryButton.sizes[size || "lg"]
        : primaryButton.sizes[size || "lg"];

    return (
      <button
        ref={outerRef}
        type={type || "button"}
        className={`tb-button ${
          variant ? "tb-button-" + variant + " " : ""
        }${sizeClasses} ${colorClasses}${className ? " " + className : ""}${
          disabled ? " disabled" : ""
        }`}
        disabled={disabled}
        onClick={onClick}
      >
        <span className="tbk-absolute tbk-top-1/2 tbk-left-2 tbk-shrink-0 tbk-grow-0 tbk--translate-y-1/2">
          {startIcon}
        </span>
        <span
          className={`tbk-text-h3-subtitle tbk-relative tbk-min-w-0 tbk-text-ellipsis ${
            startIcon ? "tbk-pl-2.5" : ""
          } ${endIcon ? "tbk-pr-2.5" : ""}`}
        >
          {showLoader && (
            <span
              className="tbk-absolute tbk-animate-spin tbk-text-blue-grey"
              style={{ left: "-32px" }}
            >
              <LoaderSpinnerIcon />
            </span>
          )}
          {children}
        </span>
        <span className="tbk-absolute tbk-top-1/2 tbk-right-1 tbk-shrink-0 tbk-grow-0 tbk--translate-y-1/2">
          {endIcon}
        </span>
      </button>
    );
  }
);

Button.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.any,
  type: PropTypes.string,
  showLoader: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  startIcon: PropTypes.object,
  endIcon: PropTypes.object,
  children: PropTypes.any,
};

export default Button;
