import React from "react";

// Icons
import ArrowRightIcon from "../../icons/ArrowRightIcon";

export const SliderNextButton = ({
  className,
  buttonClassName,
  iconClassName,
  style,
  onClick,
}) => (
  <button
    className={`${className ? `${className} ` : ""}${
      buttonClassName ? `${buttonClassName} ` : ""
    }`}
    style={style}
    onClick={onClick}
  >
    <ArrowRightIcon
      className={`${iconClassName ? `${iconClassName} ` : ""}tbk-inline`}
    />
  </button>
);

export default SliderNextButton;
